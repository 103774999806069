import React from "react";
import { createRoot } from "react-dom/client";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "react-query";

import App from "./App";
import "./index.css";

// const config = {
//   initialColorMode: "dark",
//   useSystemColorMode: false,
// };

Amplify.configure({
  aws_project_region: process.env.REACT_APP_REGION,
  aws_cognito_region: process.env.REACT_APP_REGION,
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
});

const theme = extendTheme({
  colors: {
    maroon: {
      50: "#ffe5e5",
      100: "#fbb4b4",
      200: "#f28282",
      300: "#e94f4f",
      400: "#e02020",
      500: "#c70d0d", // base maroon color
      600: "#9b0808",
      700: "#700404",
      800: "#450101",
      900: "#1e0000",
    },
  },
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <QueryClientProvider client={queryClient}>
      <ChakraProvider resetCSS theme={theme}>
        <Authenticator.Provider>
          <App />
        </Authenticator.Provider>
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
