import {
  Container,
  Heading,
  Text,
  VStack,
  Box,
  SimpleGrid,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  FaClock,
  FaThermometerHalf,
  FaCalendarCheck,
  FaSnowflake,
} from "react-icons/fa";

import EmailSignup from "../components/general/EmailSignup";

function Home() {
  const boxBg = useColorModeValue(
    "rgba(45, 45, 45, 0.8)",
    "rgba(45, 45, 45, 0.8)"
  );
  const textColor = useColorModeValue("gray.100", "gray.100");
  const borderColor = useColorModeValue(
    "rgba(255, 255, 255, 0.1)",
    "rgba(255, 255, 255, 0.1)"
  );

  return (
    <Container maxW="container.xl" py={12}>
      {/* Hero Section */}
      <Box
        position="relative"
        borderRadius="2xl"
        overflow="hidden"
        mb={16}
        bg="linear-gradient(135deg, rgba(128, 0, 0, 0.1) 0%, rgba(45, 45, 45, 0.9) 100%)"
        p={8}
      >
        <VStack spacing={8} textAlign="center">
          <Heading
            size="2xl"
            color={textColor}
            textShadow="0 2px 4px rgba(0,0,0,0.3)"
          >
            Welcome to{" "}
            <Text
              as="span"
              color="maroon.300"
              position="relative"
              _after={{
                content: '""',
                position: "absolute",
                bottom: "-2px",
                left: 0,
                width: "100%",
                height: "2px",
                background: "maroon.300",
                transform: "scaleX(0)",
                transition: "transform 0.3s ease",
              }}
              _hover={{
                _after: {
                  transform: "scaleX(1)",
                },
              }}
            >
              Hot Spot
            </Text>
          </Heading>
          <Text
            fontSize="xl"
            color="gray.300"
            maxW="800px"
            textShadow="0 1px 2px rgba(0,0,0,0.2)"
          >
            Experience the ultimate relaxation with our premium sauna services.
            <br />
            Coming soon to Fort Collins.
          </Text>

          {/* Email Signup Component */}
          <Box
            py={12}
            px={{ base: 4, md: 8 }}
            mb={4}
            mt={4}
            borderRadius="lg"
            bg={boxBg}
            borderWidth="1px"
            borderColor={borderColor}
            backdropFilter="blur(10px)"
            boxShadow="0 8px 32px rgba(0, 0, 0, 0.1)"
          >
            <EmailSignup />
          </Box>
        </VStack>
      </Box>

      {/* Features Section */}
      <SimpleGrid
        columns={{ base: 1, md: 4 }}
        spacing={10}
        mb={16}
        sx={{
          "& > div": {
            backdropFilter: "blur(10px)",
          },
        }}
      >
        <Feature
          icon={FaClock}
          title="Flexible Hours"
          text="Book sessions at your convenience with our extended operating hours"
          boxBg={boxBg}
          textColor={textColor}
          borderColor={borderColor}
        />
        <Feature
          icon={FaThermometerHalf}
          title="Premium Saunas"
          text="Experience our state-of-the-art saunas"
          boxBg={boxBg}
          textColor={textColor}
          borderColor={borderColor}
        />
        <Feature
          icon={FaSnowflake}
          title="Cold Plunges"
          text="Boost recovery and mental clarity with our premium cold plunge pools"
          boxBg={boxBg}
          textColor={textColor}
          borderColor={borderColor}
        />
        <Feature
          icon={FaCalendarCheck}
          title="Easy Booking"
          text="Simple and quick online booking process"
          boxBg={boxBg}
          textColor={textColor}
          borderColor={borderColor}
        />
      </SimpleGrid>

      {/* Why Choose Us section */}
      <Box
        mb={16}
        position="relative"
        borderRadius="2xl"
        overflow="hidden"
        bg="linear-gradient(135deg, rgba(128, 0, 0, 0.1) 0%, rgba(45, 45, 45, 0.9) 100%)"
        p={8}
      >
        <Heading
          size="xl"
          textAlign="center"
          mb={8}
          color={textColor}
          textShadow="0 2px 4px rgba(0,0,0,0.3)"
        >
          Why Choose Us?
        </Heading>
        <Text
          fontSize="lg"
          textAlign="center"
          color="gray.300"
          maxW="800px"
          mx="auto"
          px={4}
          textShadow="0 1px 2px rgba(0,0,0,0.2)"
        >
          We are dedicated to providing the best sauna and cold plunge
          experience in Fort Collins. Our state-of-the-art facilities and
          exceptional customer service set us apart.
        </Text>
      </Box>
    </Container>
  );
}

function Feature({ icon, title, text, boxBg, textColor, borderColor }) {
  return (
    <Box
      textAlign="center"
      bg={boxBg}
      p={8}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      transition="all 0.4s ease"
      position="relative"
      role="group"
      _hover={{
        transform: "translateY(-5px)",
        borderColor: "maroon.400",
        boxShadow: "0 20px 40px rgba(0,0,0,0.2)",
      }}
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "lg",
        background:
          "linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 100%)",
        opacity: 0,
        transition: "opacity 0.4s ease",
      }}
    >
      <Icon
        as={icon}
        w={10}
        h={10}
        color="maroon.300"
        mb={4}
        transform="rotate(0deg)"
        transition="transform 0.3s ease"
        _groupHover={{
          transform: "rotate(360deg)",
        }}
      />
      <Heading
        size="md"
        mb={2}
        color={textColor}
        textShadow="0 1px 2px rgba(0,0,0,0.2)"
      >
        {title}
      </Heading>
      <Text color="gray.300" textShadow="0 1px 2px rgba(0,0,0,0.2)">
        {text}
      </Text>
    </Box>
  );
}

export default Home;
