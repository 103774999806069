import { useState } from "react";
import {
  Box,
  Input,
  Button,
  VStack,
  Text,
  useToast,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

function EmailSignup() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const baseUrl = process.env.REACT_APP_EMAIL_API_BASE_URL;
      const response = await fetch(`${baseUrl}/save-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error("Failed to save email");
      }

      toast({
        title: "Thank you for your interest!",
        description: "We'll keep you updated about our launch.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setEmail("");
    } catch (error) {
      toast({
        title: "Error",
        description: "Something went wrong. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box as="form" onSubmit={handleSubmit} width="100%" maxW="500px">
      <VStack spacing={4}>
        <Text color="gray.300" textAlign="center">
          Be the first to know when we launch!
          <br />
          Sign up for exclusive updates and early access.
        </Text>
        <InputGroup size="lg">
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            bg="gray.700"
            borderColor="gray.600"
            color="white"
            _placeholder={{ color: "gray.400" }}
            _hover={{ borderColor: "maroon.400" }}
            _focus={{
              borderColor: "maroon.500",
              boxShadow: "0 0 0 1px #c70d0d",
            }}
          />
          <InputRightElement width="auto" pr={1}>
            <Button
              type="submit"
              colorScheme="maroon"
              size="sm"
              isLoading={isLoading}
              px={6}
            >
              Notify Me
            </Button>
          </InputRightElement>
        </InputGroup>
        <Text fontSize="sm" color="gray.400">
          We respect your privacy. No spam, ever.
        </Text>
      </VStack>
    </Box>
  );
}

export default EmailSignup;
