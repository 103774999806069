const API_BASE_URL = process.env.REACT_APP_USERS_API_BASE_URL;

export const createUser = async (userData) => {
  console.log("userData", userData);
  const response = await fetch(`${API_BASE_URL}/users`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });

  if (!response.ok) {
    throw new Error("Failed to create user");
  }

  return response.json();
};
