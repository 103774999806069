import {
  Box,
  Flex,
  Button,
  Image,
  Link as ChakraLink,
  useColorModeValue,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import { useAuthenticator } from "@aws-amplify/ui-react";

import hotspotLogo from "../../assets/hotspotlogo.png";

function Navbar() {
  const isProduction = process.env.NODE_ENV === "production";
  const location = useLocation();
  const { authStatus, signOut } = useAuthenticator((context) => [
    context.authStatus,
    context.signOut,
  ]);
  const textColor = useColorModeValue("white", "white");
  const accentColor = useColorModeValue("maroon.500", "maroon.300");

  const showFullNavbar =
    !isProduction ||
    ["/login", "/bookings", "/account"].includes(location.pathname);

  // Simplified navbar for production
  if (isProduction && !showFullNavbar) {
    return (
      <Box
        bg="linear-gradient(135deg, rgba(128, 0, 0, 0.1) 0%, rgba(45, 45, 45, 0.9) 100%)"
        px={4}
        shadow="md"
        position="relative"
      >
        <Flex h={16} alignItems="center" justifyContent="center">
          <ChakraLink as={RouterLink} to="/" display="flex" alignItems="center">
            <Image src={hotspotLogo} alt="Hotspot Logo" h="40px" />
          </ChakraLink>
        </Flex>
      </Box>
    );
  }

  // Full navbar for development
  return (
    <Box
      bg="linear-gradient(135deg, rgba(128, 0, 0, 0.1) 0%, rgba(45, 45, 45, 0.9) 100%)"
      px={4}
      shadow="md"
      position="relative"
    >
      {/* Remove the old gradient overlay since we now have the matching gradient background */}
      <Flex h={16} alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <ChakraLink as={RouterLink} to="/" display="flex" alignItems="center">
            <Image src={hotspotLogo} alt="Hotspot Logo" h="40px" mr={2} />
          </ChakraLink>
        </Flex>

        <Flex alignItems="center" gap={6}>
          <ChakraLink
            as={RouterLink}
            to="/about"
            color={textColor}
            _hover={{
              color: accentColor,
              textDecoration: "none",
            }}
            fontWeight="medium"
          >
            About Us
          </ChakraLink>

          <ChakraLink
            as={RouterLink}
            to="/bookings"
            color={textColor}
            _hover={{
              color: accentColor,
              textDecoration: "none",
            }}
            fontWeight="medium"
          >
            Book Sauna
          </ChakraLink>

          {authStatus === "authenticated" ? (
            <>
              <ChakraLink
                as={RouterLink}
                to="/account"
                color={textColor}
                _hover={{
                  color: accentColor,
                  textDecoration: "none",
                }}
                fontWeight="medium"
              >
                My Account
              </ChakraLink>
              <Button
                colorScheme="maroon"
                size="sm"
                variant="outline"
                _hover={{
                  bg: "maroon.500",
                  color: "white",
                  transform: "translateY(-1px)",
                  boxShadow: "md",
                }}
                _active={{
                  transform: "translateY(0)",
                  boxShadow: "sm",
                }}
                transition="all 0.2s"
                onClick={signOut}
              >
                Sign Out
              </Button>
            </>
          ) : (
            <Button
              as={RouterLink}
              to="/login"
              colorScheme="maroon"
              size="sm"
              _hover={{
                transform: "translateY(-1px)",
                boxShadow: "md",
              }}
              _active={{
                transform: "translateY(0)",
                boxShadow: "sm",
              }}
              transition="all 0.2s"
            >
              Sign In
            </Button>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}

export default Navbar;
