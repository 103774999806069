import {
  Box,
  Button,
  Container,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  VStack,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Heading,
  Text,
  Stack,
  Badge,
  Divider,
} from "@chakra-ui/react";

function UserAccount() {
  const toast = useToast();

  // Mock data - replace with actual user data
  const mockUserData = {
    membership: {
      type: "Premium",
      status: "Active",
      renewalDate: "2024-05-01",
    },
    paymentMethod: {
      type: "Credit Card",
      last4: "4242",
      expiryDate: "05/25",
    },
  };

  const mockBookings = [
    {
      id: "1",
      date: "2024-03-15",
      time: "10:00 AM",
      duration: "60 min",
      type: "Private Sauna",
      status: "Completed",
    },
    {
      id: "2",
      date: "2024-03-20",
      time: "2:30 PM",
      duration: "90 min",
      type: "Group Sauna",
      status: "Upcoming",
    },
    {
      id: "3",
      date: "2024-03-10",
      time: "4:00 PM",
      duration: "60 min",
      type: "Private Sauna",
      status: "Cancelled",
    },
  ];

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "completed":
        return "green";
      case "upcoming":
        return "blue";
      case "cancelled":
        return "red";
      default:
        return "gray";
    }
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    // Add your password change logic here
    toast({
      title: "Password updated",
      description: "Your password has been successfully changed.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <Container maxW="container.lg" py={8}>
      <Heading mb={6}>My Account</Heading>

      <Tabs>
        <TabList>
          <Tab>Profile</Tab>
          <Tab>Membership</Tab>
          <Tab>Booking History</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Stack spacing={6}>
              {/* Personal Information */}
              <Box p={5} shadow="md" borderWidth="1px" borderRadius="md">
                <Heading size="md" mb={4}>
                  Personal Information
                </Heading>
                <VStack spacing={4} align="stretch">
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input type="email" value="user@example.com" isReadOnly />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input type="text" value="John Doe" isReadOnly />
                  </FormControl>
                </VStack>
              </Box>

              {/* Change Password */}
              <Box p={5} shadow="md" borderWidth="1px" borderRadius="md">
                <Heading size="md" mb={4}>
                  Change Password
                </Heading>
                <form onSubmit={handlePasswordChange}>
                  <VStack spacing={4} align="stretch">
                    <FormControl isRequired>
                      <FormLabel>Current Password</FormLabel>
                      <Input
                        type="password"
                        placeholder="Enter current password"
                      />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel>New Password</FormLabel>
                      <Input type="password" placeholder="Enter new password" />
                    </FormControl>
                    <FormControl isRequired>
                      <FormLabel>Confirm New Password</FormLabel>
                      <Input
                        type="password"
                        placeholder="Confirm new password"
                      />
                    </FormControl>
                    <Button
                      type="submit"
                      colorScheme="blue"
                      size="md"
                      width="fit-content"
                    >
                      Update Password
                    </Button>
                  </VStack>
                </form>
              </Box>
            </Stack>
          </TabPanel>
          <TabPanel>
            <Stack spacing={6}>
              {/* Membership Information */}
              <Box p={5} shadow="md" borderWidth="1px" borderRadius="md">
                <Heading size="md" mb={4}>
                  Current Membership
                </Heading>
                <Stack spacing={3}>
                  <Box>
                    <Text fontWeight="bold" display="inline-mr">
                      Membership Type:{" "}
                    </Text>
                    <Badge colorScheme="green" ml={2}>
                      {mockUserData.membership.type}
                    </Badge>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" display="inline">
                      Status:{" "}
                    </Text>
                    <Badge colorScheme="green" ml={2}>
                      {mockUserData.membership.status}
                    </Badge>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" display="inline">
                      Renewal Date:{" "}
                    </Text>
                    <Text display="inline" ml={2}>
                      {mockUserData.membership.renewalDate}
                    </Text>
                  </Box>
                </Stack>
              </Box>

              {/* Payment Method */}
              <Box p={5} shadow="md" borderWidth="1px" borderRadius="md">
                <Heading size="md" mb={4}>
                  Payment Method
                </Heading>
                <Stack spacing={3}>
                  <Box>
                    <Text fontWeight="bold" display="inline">
                      Type:{" "}
                    </Text>
                    <Text display="inline" ml={2}>
                      {mockUserData.paymentMethod.type}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" display="inline">
                      Card Number:{" "}
                    </Text>
                    <Text display="inline" ml={2}>
                      •••• {mockUserData.paymentMethod.last4}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontWeight="bold" display="inline">
                      Expiry Date:{" "}
                    </Text>
                    <Text display="inline" ml={2}>
                      {mockUserData.paymentMethod.expiryDate}
                    </Text>
                  </Box>
                  <Divider />
                  <Button colorScheme="blue" size="sm" width="fit-content">
                    Update Payment Method
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </TabPanel>

          <TabPanel>
            <Box p={5} shadow="md" borderWidth="1px" borderRadius="md">
              <Heading size="md" mb={4}>
                Booking History
              </Heading>
              {mockBookings.length > 0 ? (
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Date</Th>
                      <Th>Time</Th>
                      <Th>Duration</Th>
                      <Th>Type</Th>
                      <Th>Status</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {mockBookings.map((booking) => (
                      <Tr key={booking.id}>
                        <Td>{booking.date}</Td>
                        <Td>{booking.time}</Td>
                        <Td>{booking.duration}</Td>
                        <Td>{booking.type}</Td>
                        <Td>
                          <Badge colorScheme={getStatusColor(booking.status)}>
                            {booking.status}
                          </Badge>
                        </Td>
                        <Td>
                          {booking.status === "Upcoming" && (
                            <Button
                              colorScheme="red"
                              size="sm"
                              onClick={() => {
                                // Add cancellation logic here
                                toast({
                                  title: "Booking Cancelled",
                                  description:
                                    "Your booking has been cancelled successfully.",
                                  status: "success",
                                  duration: 3000,
                                  isClosable: true,
                                });
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              ) : (
                <Box textAlign="center" py={10}>
                  <Text fontSize="lg" color="gray.600">
                    No bookings found
                  </Text>
                  <Button
                    colorScheme="blue"
                    size="md"
                    mt={4}
                    onClick={() => {
                      // Add navigation to booking page
                    }}
                  >
                    Book a Sauna
                  </Button>
                </Box>
              )}
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
}

export default UserAccount;
