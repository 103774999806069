import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { signUp, confirmSignUp, signIn } from "@aws-amplify/auth";
import { Box, Container, Heading } from "@chakra-ui/react";
import "@aws-amplify/ui-react/styles.css";

import { createUser } from "../api/createUser";

function Login() {
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const userRef = useRef({});

  useEffect(() => {
    if (authStatus === "authenticated") {
      navigate("/bookings");
    }
  }, [authStatus, navigate]);

  const handleSignUp = async (formData) => {
    try {
      const { username, password, options } = formData;
      const { email, name } = options.userAttributes;
      const signUpData = await signUp({
        username,
        password,
        options: {
          userAttributes: {
            email,
            name,
          },
        },
      });

      const { nextStep, userId } = signUpData;

      if (nextStep.signUpStep === "CONFIRM_SIGN_UP") {
        const userData = { cognitoSub: userId, email, name, password };
        userRef.current = userData;
      }

      // Return the required format
      return signUpData;
    } catch (error) {
      console.error("Error during sign up:", error);
      throw error;
    }
  };

  const handleConfirmSignUp = async ({ username, confirmationCode }) => {
    const signUpData = await confirmSignUp({
      username,
      confirmationCode,
    });

    if (signUpData.isSignUpComplete) {
      const { email, name, cognitoSub, password } = userRef.current;
      await createUser({
        email,
        name,
        cognitoSub,
      });

      await signIn({
        username,
        password,
      });
    }

    return signUpData;
  };

  return (
    <Container maxW="container.sm" py={8}>
      <Box shadow="md" rounded="lg" p={6} bg="white">
        <Heading mb={6} textAlign="center">
          Welcome to Hotspot
        </Heading>
        <Authenticator
          signUpAttributes={["email", "name"]}
          services={{
            handleSignUp,
            handleConfirmSignUp,
          }}
        />
      </Box>
    </Container>
  );
}

export default Login;
