import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { fetchSlotsByDate } from "../api/getSlots";
import { createBooking } from "../api/createBooking";
import {
  Container,
  Heading,
  Text,
  SimpleGrid,
  Box,
  Spinner,
  Alert,
  AlertIcon,
  Badge,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "../index.css";

function Bookings() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedSlot, setSelectedSlot] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const formattedDate = selectedDate.toISOString().split("T")[0]; // YYYY-MM-DD

  const {
    data: slots,
    isLoading,
    isError,
    error,
  } = useQuery(["slots", formattedDate], () => fetchSlotsByDate(formattedDate));

  const createBookingMutation = useMutation(createBooking, {
    onSuccess: () => {
      // Refresh slots after successful booking creation
      queryClient.invalidateQueries(["slots", formattedDate]);
      onClose();
    },
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSlotClick = (slot) => {
    if (slot.status === "AVAILABLE") {
      setSelectedSlot(slot);
      onOpen();
    }
  };

  const handleConfirmBooking = () => {
    if (selectedSlot) {
      const bookingData = {
        userId: "user123",
        bookingDate: formattedDate,
        bookingHour: selectedSlot.startHour,
      };
      createBookingMutation.mutate(bookingData);
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Heading mb={6}>Book a Sauna Session</Heading>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
        {/* Calendar Section */}
        <Box shadow="md" rounded="lg" p={4}>
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            minDate={new Date()}
            maxDate={new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)}
          />
        </Box>

        {/* Time Slots Section */}
        <Box shadow="md" rounded="lg" p={4}>
          <Heading size="md" mb={4}>
            Available Times for {formattedDate}
          </Heading>

          {/* Handle Loading State */}
          {isLoading && (
            <Box textAlign="center">
              <Spinner size="xl" />
              <Text>Loading slots...</Text>
            </Box>
          )}

          {/* Handle Error State */}
          {isError && (
            <Alert status="error">
              <AlertIcon />
              {error.message}
            </Alert>
          )}

          {/* Display Slots */}
          {slots && slots.slots.length > 0 ? (
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
              {slots.slots.map((slot) => (
                <Box
                  key={`${slot.startHour}-${slot.endHour}`}
                  p={4}
                  borderWidth="1px"
                  borderRadius="lg"
                  borderColor="gray.200"
                  shadow="md"
                  cursor={
                    slot.status === "AVAILABLE" ? "pointer" : "not-allowed"
                  }
                  onClick={() => handleSlotClick(slot)}
                >
                  <Text fontWeight="bold">
                    {slot.startHour}:00 - {slot.endHour}:00
                  </Text>
                  <Badge
                    colorScheme={slot.status === "AVAILABLE" ? "green" : "red"}
                    mb={2}
                  >
                    {slot.status}
                  </Badge>
                  <Text>Spots Remaining: {slot.spotsRemaining}</Text>
                  <Text>Waitlist Spots: {slot.waitlistSpotsRemaining}</Text>
                </Box>
              ))}
            </SimpleGrid>
          ) : (
            !isLoading && <Text>No slots available for this date.</Text>
          )}
        </Box>
      </SimpleGrid>

      {/* Booking Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Booking</ModalHeader>
          <ModalBody>
            {selectedSlot && (
              <Text>
                Are you sure you want to book the {selectedSlot.startHour}:00 -{" "}
                {selectedSlot.endHour}:00 slot?
              </Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handleConfirmBooking}
              isLoading={createBookingMutation.isLoading}
            >
              Confirm
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Current Bookings Section */}
      <Box mt={8} shadow="md" rounded="lg" p={4}>
        <Heading size="md" mb={4}>
          Your Upcoming Bookings
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
          {/* Example booking */}
          <Box p={4} borderWidth="1px" rounded="lg" borderColor="gray.200">
            <Text fontWeight="bold">Sauna Session</Text>
            <Text>Date: Tomorrow</Text>
            <Text>Time: 14:00</Text>
            <Badge colorScheme="green">Confirmed</Badge>
          </Box>
        </SimpleGrid>
      </Box>
    </Container>
  );
}

export default Bookings;
