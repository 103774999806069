const API_BASE_URL = process.env.REACT_APP_BOOKINGS_API_BASE_URL;

export const fetchSlotsByDate = async (date) => {
  const response = await fetch(`${API_BASE_URL}/slots?date=${date}`, {
    headers: {
      "Content-Type": "application/json",
      Origin: "*",
    },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch slots");
  }
  return response.json();
};
